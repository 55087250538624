import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';

const config = {
  apiKey: window?.appConfig?.REACT_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: window?.appConfig?.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
};

const firebaseApp = initializeApp(config);
export const authInstance = getAuth(firebaseApp);
export const provider = new GoogleAuthProvider();

const customProvider = new OAuthProvider(`${window?.appConfig?.REACT_APP_AUTH_PROTOCOL}.${window?.appConfig?.REACT_APP_AUTH_PROVIDER_ID}`);

export { customProvider };

export default firebaseApp;
