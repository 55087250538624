import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import { ThemeContextProvider } from './context/ThemeContext';
import FirebaseInit from './services/utils/FirebaseInit';

const getApp = () => {
  if (window?.appConfig?.REACT_APP_AUTH_PROVIDER === 'FIREBASE') {
    return (
      <FirebaseInit>
        <Provider store={store}>
          <ThemeContextProvider>
            <App />
          </ThemeContextProvider>
        </Provider>
      </FirebaseInit>
    );
  }
  if (window?.appConfig?.REACT_APP_AUTH_PROVIDER === 'COGNITO') {
    return (
      <Provider store={store}>
        <ThemeContextProvider>
          <App />
        </ThemeContextProvider>
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <ThemeContextProvider>
        <App />
      </ThemeContextProvider>
    </Provider>
  );
};

ReactDOM.render(getApp(), document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorker.unregister();
