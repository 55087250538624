import React, { useEffect, useState } from 'react';
import {
  Outlet, Navigate, useLocation, useNavigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useUserAuth } from '../context/UserAuthContext';
import usePermissions from '../hooks/usePermissions';
import { routePermissions } from '../services/constants/constants';
import useLogout from '../hooks/useLogout';

function ProtectedOutlet() {
  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useUserAuth();
  const { checkPermission } = usePermissions();
  const { logoutUser } = useLogout();

  const { permissions } = useSelector((state) => state.system);

  const [tabCloseTimeoutVerified, setTabCloseTimeoutVerified] = useState(false);

  useEffect(() => {
    if (permissions?.length > 0) {
      const requiredPermissions = routePermissions[location.pathname];
      // if no specific permission is required for the route, allow access
      if (requiredPermissions) {
        const hasRequiredPermissions = requiredPermissions
          .every((permission) => checkPermission(permission));

        if (!hasRequiredPermissions) {
          navigate(location?.state ? location.state.from.pathname + location.state.from.search : '/home/application-dashboard', { replace: true });
        }
      }
    }
  }, [permissions, location.pathname]);

  useEffect(async () => {
    const currentTime = Date.now();
    const IDLE_TIME_LIMIT = window.appConfig.REACT_APP_IDLE_TIMEOUT * 60;

    if (user && user.loggedInTime) {
      const difference = ((currentTime - user.loggedInTime) / 60000);
      if (difference >= IDLE_TIME_LIMIT) {
        await logoutUser();
        navigate('/login', { state: { from: location } });
      } else {
        setTabCloseTimeoutVerified(true);
      }
    } else {
      setTabCloseTimeoutVerified(true);
    }
  }, [user]);

  return (
    tabCloseTimeoutVerified && (
      (user && user.token) ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />
    )
  );
}

export default ProtectedOutlet;
